import { useEffect, useState } from "react"
import { buildEventList } from "../_shared/functions/createRepeatEvents"

// Build the event list (sort and create repeating dates)
// e.g. generate all the repeating event dates (like the Language exchange)
// store in memory.
export const useBuildDates = ( dates ) => {
  const [ sortedEventList, setSortedEventList ] = useState(false)

  useEffect(() => {
    const sortedEventList = buildEventList (dates)
    setSortedEventList(sortedEventList)
    // const allDates = createRepeatEvents(dates)
    // setSortedEventList(allDates)
    console.log ('dates [#33r]: ', dates)
  }, [])

  return sortedEventList
}