import React  from "react";

import Layout from "../components/Layout";
// import { useMetaDataQuery } from "../../hooks/useMetaDataQuery";
import { useUpcomingEvents } from "../hooks/useUpcomingEvents";
import moment from "moment";
import { useBuildDates } from "../hooks/useBuildDates";
import getSlug from "../functions/getSlug";
import queryString from 'query-string'
import { useLocation } from "@gatsbyjs/reach-router";

const EventsList = () => {
  // get the url query param "eptp" (Email PassThrough Parameter). This will be used as the SCD parameter in the email links.
  const { search } = useLocation()
  let eptp = queryString.parse(search)['eptp']
  console.log('eptp [erf]: ', eptp)
  
  const dates = useUpcomingEvents();
  console.log('dates [sd5]: ', dates)
  const allDates = useBuildDates(dates)
  console.log('useUpcomingEvents [xc3]: ', allDates)

  const todoStyle = {
    color: "red"
  }
  
  return (
    <>
      (for instructions on this page. Please see: NTM / OTA > SBM-040)
        <br /><br /><b>Email SUBJECT:</b>
        <br/ >New To Melbourne! Upcoming (Free) events and sightseeing tours

        <br/ ><br/ ><b>Email BODY:</b>
        <br />Hey Adventurers! :-)

        <br /><br/>[<span style={todoStyle}><b>TODO</b>: write 1-2 paragraph intro here. Generally focus on the things people can see on a trip or why we're different (like our low price / affordability) or the other like minded travellers / international students they can meet.
        See: <a href="https://docs.google.com/presentation/d/1L7Yc4DGgRfj0o4YTZmb8WJp6OZDYmnSrfqrX0l7uU0s/edit#slide=id.g21dd81dea2c_0_51">NTM USPs</a></span>]

        <br /><br/><b>Upcoming event & trip dates:</b>
        <ul>
          <li>? [events_rr6]</li>
          <li>[<span style={todoStyle}><b>TODO LATER</b>: add the Events list links below</span> ]</li>
        </ul>

        <b>NEW! trips - announced today!:</b>
        <br />The trips marked <b><u>NEW!</u></b> have the cheapest tickets available. (as they have just been created). People on our email list (like yourself) are the first to get access to cheap tickets :-)

        <br /><br /><b>Avoid Eventbrite fees / pay in cash:</b>
        <br />Eventbrite fees are usually $6-7 per person, you can avoid this fee (for a cheaper ticket price); it you can pay in cash at our language exchange. For more info, please see:
        <br /><a href="https://bit.ly/NTM-CashPaymentOption">https://bit.ly/NTM-CashPaymentOption</a>

        <br /><br /><b>Useful facebook groups:</b>
        <br />
        <ul>
          <li><a href="https://www.facebook.com/groups/oztravelplanning">Australia Travel Planning</a>: our new group for people looking for travel advice, travel buddies (to share fuel costs) or people new to Melbourne looking to make new friends :-)</li>
          <li><a href="https://www.facebook.com/groups/106930279668865">Free language practice! Find a language exchange partner 🙂</a>: (10K+ members) a place to meet someone that speaks the language you want to learn</li>
          <li><a href="https://www.facebook.com/groups/socialeventsmelb">Social Events in Melbourne! (SEM)</a>: This is our main community page where we post photos, dates for upcoming events, discounted tickets and attendees (from past tours and events) can connect.</li>
        </ul>

        Hope you can join us soon :-)

        <br /><br />Cheers,
        <br />Cameron [<span style={todoStyle}><b>TODO</b>  : ensure this is the correct name as the sending account.</span> ]
        <br />Event Organiser at New To Melbourne
    </>
  )
}

// markup
const IndexPage = () => {
  return (
    <Layout>
      <EventsList />
    </Layout>
  )
};

export default IndexPage;