// const moment = require('moment');
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment";

// return a list of all events and tour dates (filter earlier than today, sort: show soonest dates first)
// (But don't calculate repeating dates - this is done elsewhere).
export const useUpcomingEvents = () => {
  const today = moment();
  const formattedDate = moment(today).format('YYYY-MM-DDTHH:mm+TZ');
  console.log('moment [33r]: ', formattedDate)

  // // working query - only show GOR:
  // const data = useStaticQuery(graphql`
  //   query ConfigQuery {
  //     allContentfulEventDate (
  //       sort: {fields: startDateTime}
  //       filter: {startDateTime: {gt: "2023-08-01T18:00+10:00"}}
  //     ) {
  //       edges {
  //         node {
  //           product {
  //             name
  //           }
  //           startDateTime
  //           id
  //           repeat
  //           endDateTime
  //           overrides {
  //             cancelled
  //             startDateTime
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  // working query:
  const data = useStaticQuery(graphql`
    query UpcomingEvents {
      allContentfulEventDate (sort: {fields: startDateTime, order: ASC}) {
    nodes {
      startDateTime
      endDateTime
      cancelled
      repeat
      overrides {
        cancelled
        startDateTime
      }
      productType {
        ... on ContentfulProduct {
          id
          name
          descriptionShort
          sellPrice
          durationInMinutes
          urlSlug {
            productName
            contentful_id
            startLocation {
              contentful_id
              parentLocation {
                title
                id
                contentful_id
                parentLocation {
                  contentful_id
                  id
                  title
                  parentLocation {
                    contentful_id
                    id
                    title
                  }
                }
              }
              id
              title
            }
          }
          featureImage {
            gatsbyImageData
            title
          }
        }
      }
    }
  }
    }
  `)

  // note: couldn't get this filter by datetime query working:
  // maybe due to static query limitations:
  // const dateTimeVariable = new Date().toISOString();
  // const data = useStaticQuery(graphql`
  //   query ConfigQuery($dateTimeVariable: Date!) {
  //     allContentfulEventDate (
  //       sort: {fields: startDateTime}
  //       filter: {startDateTime: {gt: $dateTimeVariable}}
  //     ) {
  //       edges {
  //         node {
  //           id
  //           product {
  //             name
  //           }
  //           repeat
  //           startDateTime
  //           endDateTime
  //           overrides {
  //             cancelled
  //             startDateTime
  //           }
  //         }
  //       }
  //     }
  //   }
  // `, {
  //   variables: {
  //     dateTimeVariable,
  //   },
  // })

  // 6d47e7a9-16d7-5269-8a30-f537dd02855f

  const return1 = data.allContentfulEventDate.nodes

  console.log('return [8hy] : ', return1)
  
  return return1
}